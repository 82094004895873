@import '../../mixins';

.page-title {
  width: 100%;
  padding: rem(80) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include mediaBigDesktop {
    padding: big(80) 0;
  }

  @include mediaLaptop {
    padding: rem(60) 0 rem(70);
  }

  @include mediaTablet {
    padding: rem(40) 0 rem(50);
  }

  @include mediaMobile {
    padding: rem(25) 0 rem(30);
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(50);

    @include mediaBigDesktop {
      gap: big(50);
    }

    @include mediaTablet {
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__title {
    width: 100%;
    color: var(--text-dark-primary);
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaMobile {
      flex-direction: column;
    }
  }

  &__info {
    color: var(--text-dark-tertiary);
    font-weight: 500;

    & .strong {
      color: var(--text-dark-primary);
      font-weight: 500;
    }

    & + .page-title__wishlist {
      margin-left: auto;

      @include mediaMobile {
        margin-left: 0;
      }
    }
  }

  &__wishlist {
    --accent-light-secondary: var(--system-states-error);
  }
}
